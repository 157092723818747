import React, { useState } from 'react'; // Import useState
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import './SignUpPage.css'; // Make sure to create a corresponding CSS file
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; // Corrected import
import { getFirestore, doc, setDoc } from "firebase/firestore";
import app from './firebase-config'; // Import the Firebase initialization

const SignUpPage = () => {
  const navigate = useNavigate(); // Use useNavigate hook
  const [email, setEmail] = useState(''); // useState is now properly imported
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(''); // State to handle error message

  const auth = getAuth(app); // Pass the Firebase app instance to getAuth
  const db = getFirestore(app); // Pass the Firebase app instance to getFirestore

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Account created with UID:', userCredential.user.uid);
      // Here you can redirect the user or show a success message
      addUserToFirestore(userCredential.user);
      navigate('/watch'); // Redirect to the destination page
    } catch (error) {
      console.error("Error signing up:", error.code, error.message);
      setError("Error: Account could not be created."); // Set error message
    }
  };

  const addUserToFirestore = (user) => {
    // Create a reference to the user document, which uses the user's UID as the document ID
    const userRef = doc(db, "users", user.uid);

    // Set the user document with the desired information
    setDoc(userRef, {
      email: user.email, // Changed from Phone to email
      rememberMe: rememberMe,
      // Include any additional fields you collected from the signup form
    })
    .then(() => {
      // Document successfully written!
      console.log("User information saved to Firestore");
    })
    .catch((error) => {
      // Handle any errors writing to Firestore here
      console.error("Error writing document to Firestore:", error);
    });
  };

  return (
    <div className="signup-container">
      <button onClick={() => navigate(-1)} className="back-button">
        <img src="/signup/arrow-left.png" alt="Go back" />
      </button>
      <form onSubmit={handleSignUp}>
        <h1>Create your Account</h1>
        <div className="input-group">
          <input
            type="email" // Changed from Phone to email
            placeholder="Email" // Changed from Phone to email
            value={email} // Changed from Phone to email
            onChange={(e) => setEmail(e.target.value)} // Changed from setPhone to setEmail
            required
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="checkbox-group">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label className="remember-me" htmlFor="rememberMe">Remember me</label>
        </div>
        <button type="submit" className="signup-button">Sign up</button>
        {error && <div style={{ color: 'red' }}>{error}</div>} {/* Display error message if there is an error */}
        <div className="alternative-signup">
          <span>or continue with</span>
          <div className="icons">
            <img class="icon" src="/signup/login-google.png" alt="Google" />
            <img class="icon" src="/signup/login-apple.png" alt="Apple" />
          </div>
        </div>
        <div className="signin-redirect">
          <span>Already have an account? <a href="/signin">Sign in</a></span>
        </div>
      </form>
    </div>
  );
};

export default SignUpPage;