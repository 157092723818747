import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LaunchScreen.css'; // Make sure to create a corresponding CSS file

function LaunchScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/signup');
    }, 3000); // Redirect after 2000 milliseconds

    // Cleanup the timer if the component unmounts before the timer fires
    return () => clearTimeout(timer);
  }, [navigate]); // Empty dependency array means this effect runs once on mount

  return (
    <div className="launch-screen">
    <video autoPlay muted loop playsInline className="splash-video">
        <source src="splash-vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
    </div>
  );
}

export default LaunchScreen;