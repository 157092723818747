// src/WatchPage.js
import React from 'react';
import './WatchPage.css'; // Import the CSS for styling

const WatchPage = () => {
  return (
    <div className="video-container">
      <video autoPlay muted loop id="fullscreen-video" controls>
        <source src="placeholder-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default WatchPage;
