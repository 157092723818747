// HomePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
    let navigate = useNavigate();

    useEffect(() => {
        navigate('/launch');
    }, [navigate]);

    return <h1>Home</h1>;
};

export default HomePage;