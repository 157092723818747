import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import LaunchScreen from './LaunchScreen';
import SignUpPage from './SignUpPage';
import SignInPage from './SignInPage';
import WatchPage from './WatchPage';
import './MobileContainer.css'; // The CSS file where .mobile-container is defined


function App() {
  return (
  <div className="mobile-container">
  <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/launch" element={<LaunchScreen />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/watch" element={<WatchPage />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;