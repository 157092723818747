import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase-config'; // Import the auth object from your Firebase config
import './SignInPage.css'; 

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Sign in success, redirect to the HomePage
        navigate('/watch');
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage); // Update the state to display the error message
      });
  };

  return (
    <div className="signin-container">
      <form onSubmit={handleSignIn} className="signin-form">
      <button onClick={() => navigate(-1)} className="back-button">
        <img src="/signup/arrow-left.png" alt="Go back" />
      </button>
        <h1>Sign in to your account</h1>
        <div className="input-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="input-field"
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="input-field"
          />
        </div>
        <button type="submit" className="signin-button">Sign in</button>
        {error && <p className="error-message">{error}</p>}
        <div className="alternative-signin">
          <span>or continue with</span>
          <div className="icons">
            <img class="icon" src="/signup/login-google.png" alt="Google" />
            <img class="icon" src="/signup/login-apple.png" alt="Apple" />
          </div>
        </div>
        <div className="signup-link">
          <span>Don’t have an account yet? <a href="/signup">Sign up</a></span>
        </div>
      </form>
    </div>
  );
};

export default SignInPage;
